import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import ELC_logo from './ELC_Logo.jpeg';
import { SERVER_URL } from './globals';
/** Renders page consisting of functionality for editing the exercises for the current semester */
function EditExercises() {
    const [exercises302, set302Exercises] = React.useState([]);
    const [exercises304, set304Exercises] = React.useState([]);
    const [add302ShortValue, set302AddShortValue] = React.useState('');
    const [add302Value, set302AddValue] = React.useState('');
    const [add304ShortValue, set304AddShortValue] = React.useState('');
    const [add304Value, set304AddValue] = React.useState('');
    const handleSubmit = async () => {
        window.location.href = "/create/302";
      }
      useEffect(() => {
        retrieve302Exercises();
        retrieve304Exercises();
      }, []);
  const handle302Remove = async (short, long) => {
    const neww = exercises302.filter((exercise) => exercise.short_name !== short);
    set302Exercises(neww);
    try {
        await fetch(`${SERVER_URL}/info/exercises/delete/?class=BUAD302&shortName=` + short + `&longName=` + long, { method: 'DELETE'});
    } catch (err) {
        console.log(err);
    }
  }
  const handle304Remove = async (short, long) => {
    const neww = exercises304.filter((exercise) => exercise.short_name !== short);
    set304Exercises(neww);
    try {
        await fetch(`${SERVER_URL}/info/exercises/delete/?class=BUAD304&shortName=` + short + `&longName=` + long, { method: 'DELETE'});
    } catch (err) {
        console.log(err);
    }
  }
  const retrieve302Exercises = async (val) => {
    try {
      const response = await fetch(`${SERVER_URL}/info/exercises/?class=BUAD302`);
      const data = await response.json();
      set302Exercises(data.exercises);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const retrieve304Exercises = async (val) => {
    try {
      const response = await fetch(`${SERVER_URL}/info/exercises/?class=BUAD304`);
      const data = await response.json();
      set304Exercises(data.exercises);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const exercise302Items = exercises302.map((exercise) =>
  <li className="profItem">
    <button className="profName">Short Name: {exercise.short_name} </button>
    <button onClick={() => handle302Remove(exercise.short_name, exercise.long_name)} className="deleteProf">X</button>
    <button className="profNameRight">Long Name: {exercise.long_name}</button>
</li>
    
  );

  const exercise304Items = exercises304.map((exercise) =>
  <li className="profItem">
    <button className="profName">Short Name: {exercise.short_name}</button>
    <button onClick={() => handle304Remove(exercise.short_name, exercise.long_name)} className="deleteProf">X</button>

    <button className="profNameRight">Long Name: {exercise.long_name}</button></li>
  );
  const handle302Add = async () => {
    const newList = exercises302.concat({'short_name': add302ShortValue, 'long_name': add302Value});
    set302Exercises(newList);
    try {
        await fetch(`${SERVER_URL}/info/exercises/add/?class=BUAD302&shortName=` + add302ShortValue + `&longName=` + add302Value, { method: 'PUT'});
    } catch (err) {
        console.log(err);
    }
  }
  const handle304Add = async () => {
    const newList = exercises304.concat({'short_name': add304ShortValue, 'long_name': add304Value});
    set304Exercises(newList);
    try {
        await fetch(`${SERVER_URL}/info/exercises/add/?class=BUAD304&shortName=` + add304ShortValue + `&longName=` + add304Value, { method: 'PUT'});
    } catch (err) {
        console.log(err);
    }
  }

  const handle302ShortInputValueChange = (event) => {
    set302AddShortValue(event.target.value);
  }
  const handle304ShortInputValueChange = (event) => {
    set304AddShortValue(event.target.value);
  }
  const handle302InputValueChange = (event) => {
    set302AddValue(event.target.value);
  }
  const handle304InputValueChange = (event) => {
    set304AddValue(event.target.value);
  }
  return (
    <div className="NewSemester">
      <img src={ELC_logo} className="hi"/>
      <h2 className="buadProfessors">Edit BUAD 302 Exercises</h2>
    <ul className="profList">
        {exercise302Items}
      </ul>
      <input type="text" value={add302ShortValue} onChange={handle302ShortInputValueChange} className="exerciseField" placeholder="Enter New 302 Exercise Short Name"/>
      <input type="text" value={add302Value} onChange={handle302InputValueChange} className="exerciseField" placeholder="Enter New 302 Exercise Long Name"/>
      <button className="newExerciseButton" onClick={handle302Add}>Add New 302 Exercise</button>
      <h2 className="buadProfessors">Edit BUAD 304 Exercises</h2>
    <ul className="profList">
        {exercise304Items}
      </ul>
      <input type="text" value={add304ShortValue} onChange={handle304ShortInputValueChange} className="exerciseField" placeholder="Enter New 304 Exercise Short Name"/>
      <input type="text" value={add304Value} onChange={handle304InputValueChange} className="exerciseField" placeholder="Enter New 304 Exercise Long Name"/>
      <button className="newExerciseButton" onClick={handle304Add}>Add New 304 Exercise</button>
      <button className="newExerciseSubmitButton" onClick={handleSubmit}>Save</button>
    </div>
  );
}

export default EditExercises;

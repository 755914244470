import logo from './logo.svg';
import './App.css';
import React from 'react';
import ELC_logo from './ELC_Logo.jpeg';
import { SERVER_URL } from './globals';
/** Renders page consisting of functionality for creating a new semester for the web app */
function NewSemester() {
    const [profs302, set302Profs] = React.useState([]);
    const [profs304, set304Profs] = React.useState([]);
    const [add302Value, set302AddValue] = React.useState('');
    const [add304Value, set304AddValue] = React.useState('');
    const [selectedTerm, setSelectedTerm] = React.useState('');
    const [selectedYear, setSelectedYear] = React.useState('');
    const handleSubmit = async () => {
        try {
          const encoded302Profs = profs302.map(prof => encodeURIComponent(prof));
          const encoded304Profs = profs304.map(prof => encodeURIComponent(prof));
          const response = await fetch(`${SERVER_URL}/updateSemester/?term=${selectedYear}${selectedTerm}&buad302=${JSON.stringify(encoded302Profs)}&buad304=${JSON.stringify(encoded304Profs)}`);
          if (response.ok) {
            window.location.href = "/editTrainers";
          }
        } catch (error) {
          console.log(error);
        }
      }
const backToHome = () => {
    window.location.href = "/";
    }
  function handle302Remove(name) {
    const neww = profs302.filter((it) => it !== name);
    set302Profs(neww);
  }
  function handle304Remove(name) {
    const neww = profs304.filter((it) => it !== name);
    set304Profs(neww);
  }
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'Spring') {
        setSelectedTerm('1');
    }
    else if (selectedValue === 'Summer') {
        setSelectedTerm('2');
    }
    else if (selectedValue === 'Fall') {
        setSelectedTerm('3');
    }
  }
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const prof302Items = profs302.map((profName) =>
  <li className="profItem">
    <button className="profName">{profName}</button>
    <button onClick={() => handle302Remove(profName)} className="deleteProf">X</button></li>
  );

  const prof304Items = profs304.map((profName) =>
  <li className="profItem">
    <button className="profName">{profName}</button>
    <button onClick={() => handle304Remove(profName)} className="deleteProf">X</button></li>
  );
  function handle302Add() {
    const newList = profs302.concat(add302Value);
    set302Profs(newList);
  }
  function handle304Add() {
    const newList = profs304.concat(add304Value);
    set304Profs(newList);
  }
  const handle302InputValueChange = (event) => {
    set302AddValue(event.target.value);
  }
  const handle304InputValueChange = (event) => {
    set304AddValue(event.target.value);
  }
  return (
    <div className="NewSemester">
      <img src={ELC_logo} className="hi"/>
      <button className="newSemesterBackButton" onClick={backToHome}>Back to Home</button>
      <h1 className="createNewSurvey">Create New Semester</h1>
      <select className="termList" onChange={handleDropdownChange}>
        <option selected disabled className="surveyItem">Select the Term</option>
        <option value="Fall" className="surveyItem">Fall</option>
        <option value="Spring" className="surveyItem">Spring</option>
        <option value="Summer" className="surveyItem">Summer</option>
      </select>
      <input type="text" className="termField" onChange={handleYearChange} placeholder="Enter the Year"/>
      <h2 className="buadProfessors">BUAD 302 Professors</h2>
    <ul className="profList">
        {prof302Items}
      </ul>
      <input type="text" value={add302Value} onChange={handle302InputValueChange} className="termField" placeholder="Enter New 302 Professor Name"/>
      <button className="newSemesterProfButton" onClick={handle302Add}>Add New Professor</button>
      <h2 className="buadProfessors">BUAD 304 Professors</h2>
    <ul className="profList">
        {prof304Items}
      </ul>
      <input type="text" value={add304Value} onChange={handle304InputValueChange} className="termField" placeholder="Enter New 304 Professor Name"/>
      <button className="newSemesterProfButton" onClick={handle304Add}>Add New Professor</button>
      <button className="newSemesterSubmitButton" onClick={handleSubmit}>Create New Semester</button>
    </div>
  );
}

export default NewSemester;

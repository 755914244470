import './App.css';
import './Home.css';
import React, { useState, useEffect } from 'react';
import ELC_logo from './ELC_Logo.jpeg';
import loadingAnim from './media/loading.svg'
import { SERVER_URL } from './globals';

/** Renders home page consisting of existing surveys, report generation, create new semester, and view QR code  */
function Home() {
  useEffect(() => {
    getCurrentSemester();
    retrieveSurveys();
  }, []);
  const [semVal, setSemVal] = React.useState('');
  const [existingSurveys, setSurveys] = React.useState([]);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const getCurrentSemester = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/currentSemester`)
      const data = response.json();
      setSemVal(data.semester);
    } catch (error) {
      console.log(error);
    }
  }
  const handleSubmit = (link) => {
    window.open(link, '_blank');
  }

  const retrieveSurveys = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/info/activeSurveys`);
      const data = await response.json();
      setSurveys(data.surveys);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const existingSurveyItems = existingSurveys.map((survey) =>
  <li className="profItem">
    <button className="profName">{survey.name}</button>
    <button onClick={() => handleSubmit(survey.link)} className="deleteProf">Qualtrics Dashboard</button></li>
  );
  const handle302ReportGeneration = async () => {
    setShowLoadingIcon(true);
    await generateReport('BUAD302');
    setShowLoadingIcon(false);
  };
  const [blankValue, setBlankValue] = React.useState('');
  const handleBlankValueChange = (event) => {
    setBlankValue(event.target.value);
  };
  const handle304ReportGeneration = async () => {
    setShowLoadingIcon(true);
    await generateReport('BUAD304');
    setShowLoadingIcon(false);
  };

  const generateReport = async (classname) => {
    try {
      const response = await fetch(`${SERVER_URL}/results/${classname}`);
      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `report-${classname}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.log("Unable to generate report file");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const generateBlankReport = async () => {
    try {
      setShowLoadingIcon(true);
      const response = await fetch(`${SERVER_URL}/generate/blank/?name=${blankValue}`, {method: "POST"});
      if (response.ok) {
        window.location.href = "/";
      }
      setShowLoadingIcon(false);
    } catch (error) {
      console.log(error);
    }
  }

  const handleViewQRCode = () => {
    window.location.href = "qr";
  }
  const newSemester = () => {
    window.location.href += "editSemester";
  }
  return (
    <div className="Home">
      <img src={ELC_logo} className="hi"/>
      <h1 className="existingSurveysTxt">Existing Surveys</h1>
      <ul className="srveyList">
        {existingSurveyItems}
      </ul>
      <input type="text" value={blankValue} onChange={handleBlankValueChange} className="blankField" placeholder="Enter New Blank Survey Name"/>
      <button className="blankSurveyButton" onClick={generateBlankReport}>Create Blank Survey</button>
      <h1 className="generateReports">Generate Reports</h1>
      <div className="reportButtonContainer">
        <button className="generateReportButton" onClick={handle302ReportGeneration}>Generate 302 Report</button>
        <button className="generateReportButton" onClick={handle304ReportGeneration}>Generate 304 Report</button>
      </div>
      {showLoadingIcon &&
        <div className="loadingContainer">
          <img className="loadingIcon" src={loadingAnim} alt="loading icon"></img>
        </div>
      }

      <button className="newSurveyButton" onClick={newSemester}>Change Current Semester</button>
      <button className="viewQRButton" onClick={handleViewQRCode}>View QR Code</button>
    </div>
  );
}

export default Home;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportButtonContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;
    margin-bottom: 40px;
}

.generateReportButton {
    color: black;
    background-color: white;
    height: 120px;
    width: 450px;
    font-size: 30px;
    border: 3px solid #AC0015;
    border-radius: 8px;
}

.generateReportButton:hover {
    background: #c6c6c6;
    cursor: pointer;
}

.viewQRButton {
    height: 70px;
    width: 40%;
    border: solid #AC0015 3px;
    border-radius: 8px;
    font-size: 30px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: block;
}

.viewQRButton:hover {
    background: #c6c6c6;
    cursor: pointer;
}
  `, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".reportButtonContainer {\n    display: flex;\n    justify-content: space-evenly;\n    margin-top: 40px;\n    margin-bottom: 40px;\n}\n\n.generateReportButton {\n    color: black;\n    background-color: white;\n    height: 120px;\n    width: 450px;\n    font-size: 30px;\n    border: 3px solid #AC0015;\n    border-radius: 8px;\n}\n\n.generateReportButton:hover {\n    background: #c6c6c6;\n    cursor: pointer;\n}\n\n.viewQRButton {\n    height: 70px;\n    width: 40%;\n    border: solid #AC0015 3px;\n    border-radius: 8px;\n    font-size: 30px;\n    background-color: white;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 20px;\n    display: block;\n}\n\n.viewQRButton:hover {\n    background: #c6c6c6;\n    cursor: pointer;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

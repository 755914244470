import './App.css';
import './QRCodePage.css';
import ELC_logo from './ELC_Logo.jpeg';
import studentQRCode from './media/studentQRCode.png';

/** Renders page consisting of functionality for displaying the survey QR code for students to scan */
function QRCodePage() {
  return (
    <div className="SurveyCreation">
      <img src={ELC_logo} className="hi"/>
      <h1 className="createNewSurvey">QR Code Output</h1>
      <img src={studentQRCode} className="QRSample"/>
      <div className='buttonContainer'>
        <button className="homeButton" onClick={() => window.location.href = "/"}>Home</button>
      </div>
    </div>
  );
}

export default QRCodePage;

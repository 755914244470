// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonContainer {
    text-align: center;
    margin-top: 100px;
  }
  
  .homeButton {
    color: black;
    background-color: white;
    margin-bottom: 0px;
    height: 70px;
    width: 450px;
    font-size: 30px;
    border: 3px solid #AC0015;
    border-radius: 8px;
  }
  
  .homeButton:hover {
    background: #c6c6c6;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/QRCodePage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":[".buttonContainer {\n    text-align: center;\n    margin-top: 100px;\n  }\n  \n  .homeButton {\n    color: black;\n    background-color: white;\n    margin-bottom: 0px;\n    height: 70px;\n    width: 450px;\n    font-size: 30px;\n    border: 3px solid #AC0015;\n    border-radius: 8px;\n  }\n  \n  .homeButton:hover {\n    background: #c6c6c6;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import logo from './logo.svg';
import './App.css';
import ELC_logo from './ELC_Logo.jpeg';
const generateQR = () => {
  window.location.href = "/QR";
}
/** Renders page consisting of functionality for creating a custom survey for the current semester */
function CustomSurveyCreation() {
    const questions = ['The Newly Added Exercise was: ']
const questionItems = questions.map((question) =>
<tr>
<th scope="row"><input type="checkbox"/></th>
<th scope="row">1-5</th>
<th scope="row">{question}</th>
<th scope="row">X</th>
</tr>
);
  return (
    <div className="SurveyCreation">
      <img src={ELC_logo} className="hi"/>
      <h1 className="createNewSurvey">Select Survey Items</h1>
      <table className="threeOhTwoTable">
        <thead>
            <tr>
                <th scope="col"> </th>
                <th scope="col">Type</th>
                <th scope="col">Question</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            {questionItems}
        </tbody>
      </table>
      <button className="addNewProfessorButton">Add New Item</button>
      <button className="newCustomSurveyButton" onClick={generateQR}>Generate QR Code</button>
    </div>
  );
}

export default CustomSurveyCreation;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import ThreeOhTwoSurveyCreation from './302SurveyCreation';
import reportWebVitals from './reportWebVitals';
import SurveyCreation from './SurveyCreation';
import CustomSurveyCreation from './CustomSurveyCreation';
import QRCodePage from './QRCodePage';
import Student from './Student';
import NewSemester from './NewSemester';
import EditSemester from './EditSemester';
import EditExercises from './EditExercises';
import EditInstructors from './EditInstructors';
/** handles the routing and multi-page functionality of the web app */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
      <Route index element={<Home />} />
      <Route path="create" element={<SurveyCreation/>} />
      <Route path="create/302" element={<ThreeOhTwoSurveyCreation />} />
      <Route path="create/custom" element={<CustomSurveyCreation />} />
      <Route path="student" element={<Student/>} />
      <Route path="QR" element={<QRCodePage/>} />
      <Route path="newSemester" element={<NewSemester/>}/>
      <Route path="editSemester" element={<EditSemester/>}/>
      <Route path="editExercises" element={<EditExercises/>}/>
      <Route path="editTrainers" element={<EditInstructors/>}/>
  </Routes>
</BrowserRouter>

);

reportWebVitals();

import './App.css';
import React, { useState, useEffect } from 'react';
import ELC_logo from './ELC_Logo.jpeg';
import loadingAnim from './media/loading.svg'
import { SERVER_URL } from './globals';
/** Renders page consisting of functionality for creating the 302 survey for a given semester, including selecting exercises by professor */
function ThreeOhTwoSurveyCreation() {

  const [professors, setProfessors] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [checkedOptions, setCheckedOptions] = useState({});
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  useEffect(() => {
    retrieveProfessors('BUAD302');
    retrieveExercises();
  }, []);
  useEffect(() => {
    const initialCheckedOptions = {};
    professors.forEach((prof) => {
      initialCheckedOptions[prof] = {};
      exercises.forEach((exercise) => {
        initialCheckedOptions[prof][exercise.long_name] = false;
      });
    });
    setCheckedOptions(initialCheckedOptions);
  }, [professors, exercises]);

  const generateAllSurveys = async () => {
    setShowLoadingIcon(true);
    await deleteActiveSurveys();
    const generatePromises = [];
    for (const professor in checkedOptions) {
      const res = [];
      for (const exercise in checkedOptions[professor]) {
        if (checkedOptions[professor][exercise]){
          res.push(exercise);
        }
      }
      generatePromises.push(generate302Survey(professor, res))
    }
    
    generatePromises.push(generate304Survey());
    await Promise.all(generatePromises);

    setShowLoadingIcon(false);
    window.location.href = "/QR";
  }

  const deleteActiveSurveys = async () => {
    try {
      await fetch(`${SERVER_URL}/active-surveys`, {method: "DELETE"});
    } catch (err) {
      console.log(err);
    }
  }

  const generate302Survey = async (professor, profExercises) => {
    try {
      const encodedExercises = profExercises.map(exercise => encodeURIComponent(exercise));
      await fetch(`${SERVER_URL}/generate/302/?professor=${professor}&exercises=${JSON.stringify(encodedExercises)}`, {method: "POST"})
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const generate304Survey = async () => {
    try {
      await fetch(`${SERVER_URL}/generate/304/`, {method: "POST"})
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const retrieveProfessors = async (classname) => {
    try {
      const response = await fetch(`${SERVER_URL}/term/${classname}`);
      const data = await response.json();
      setProfessors(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCheckboxChange = (profName, exerciseLongName) => {
    checkedOptions[profName][exerciseLongName] = !checkedOptions[profName][exerciseLongName];
    console.log(checkedOptions);
  }

  const professorItems = professors.map((profName) => {
    return <tr>
    <th scope="row">{profName}</th>
    {exercises.map((exercise) => {
      return <th scope="row">
                <input type="checkbox" onChange = {() => handleCheckboxChange(profName, exercise.long_name)}/>
            </th>
    })}
    </tr>
  });

const retrieveExercises = async () => {
  try {
    const response = await fetch(`${SERVER_URL}/info/exercises?class=BUAD302`);
    const data = await response.json();
    console.log(data.exercises);
    setExercises(data.exercises);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
const exerciseItems = exercises.map((exercise) => 
<th value={exercise.long_name} scope="col">{exercise.short_name}</th>
);

  return (
    <div className="SurveyCreation">
      <img src={ELC_logo} className="hi"/>
      <h1 className="createNewSurvey">Select Exercises By Professor</h1>
      <table className="threeOhTwoTable">
        <thead>
            <tr>
            <th scope="col"></th>
              {exerciseItems}
            </tr>
        </thead>
        <tbody>
            {professorItems}
        </tbody>
      </table>
      <button className="newCustomSurveyButton" onClick={generateAllSurveys}>Generate QR Code</button>
      {showLoadingIcon &&
        <div className="loadingContainer">
          <img className="loadingIcon" src={loadingAnim} alt="loading icon"></img>
        </div>
      }
    </div>
  );
}

export default ThreeOhTwoSurveyCreation;

import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import ELC_logo from './ELC_Logo.jpeg';
import { SERVER_URL } from './globals';
/** Renders page consisting of functionality for editing the trainers for the current semester */
function EditInstructors() {
    const [instructors, setInstructors] = React.useState([]);
    const [instructorValue, setInstructorValue] = React.useState('');
    const handleSubmit = async () => {
        window.location.href = "/editExercises";
      }
      useEffect(() => {
        retrieveInstructors();
      }, []);
  const handleInstructorRemove = async (name) => {
    const neww = instructors.filter((instructor) => instructor.name !== name);
    setInstructors(neww);
    try {
        await fetch(`${SERVER_URL}/info/trainers/delete/?name=` + name, { method: 'DELETE'});
    } catch (err) {
        console.log(err);
    }
  }
  const retrieveInstructors = async (val) => {
    try {
      const response = await fetch(`${SERVER_URL}/info/trainers`);
      const data = await response.json();
      setInstructors(data.trainers);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const instructorItems = instructors.map((instructor) =>
  <li className="profItem">
    <button className="profName">{instructor.name}</button>
    <button onClick={() => handleInstructorRemove(instructor.name)} className="deleteProf">X</button>
</li>
    
  );

  const handleInstructorAdd = async () => {
    const newList = instructors.concat({'name': instructorValue});
    setInstructors(newList);
    try {
        await fetch(`${SERVER_URL}/info/trainers/add/?name=` + instructorValue, { method: 'PUT'});
    } catch (err) {
        console.log(err);
    }
  }
  const handleInstructorChange = (event) => {
    setInstructorValue(event.target.value);
  }

  return (
    <div className="NewSemester">
      <img src={ELC_logo} className="hi"/>
      <h2 className="buadProfessors">Edit ELC Trainers</h2>
    <ul className="profList">
        {instructorItems}
      </ul>
      <input type="text" value={instructorValue} onChange={handleInstructorChange} className="termField" placeholder="Enter New ELC Trainer Name"/>
      <button className="newSemesterProfButton" onClick={handleInstructorAdd}>Add New Trainer</button>
      <button className="newExerciseSubmitButton" onClick={handleSubmit}>Save</button>
    </div>
  );
}

export default EditInstructors;

import logo from './logo.svg';
import './App.css';
import ELC_logo from './ELC_Logo.jpeg';
const newCustomSurvey = () => {
  window.location.href += "/custom";
}
const new302Survey = () => {
  window.location.href += "/302";
}
/** Renders page consisting of functionality for creating a new survey for the current semester */
function SurveyCreation() {
  return (
    <div className="SurveyCreation">
      <img src={ELC_logo} className="hi"/>
      <h1 className="createNewSurvey">Create New Survey</h1>
      <button className="new304SurveyButton" onClick={newCustomSurvey}>Create 304 Survey</button>
      <button className="new302SurveyButton" onClick={new302Survey}>Create 302 Survey</button>
      <button className="newCustomSurveyButton" onClick={newCustomSurvey}>Create Custom Survey</button>
    </div>
  );
}

export default SurveyCreation;

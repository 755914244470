import './Student.css';
import React, { useState, useEffect } from 'react';
import ELC_logo from './ELC_Logo.jpeg';
import { SERVER_URL } from './globals';
/** Renders page consisting of functionality for students to select the survey they want to take */
function Student() {
  const [selectedCourse, setSelectedCourse] = useState('BUAD302');
  const [selectedProfessor, setSelectedProfessor] = useState('');
  const [showDropdown, setShowDropdown] = useState(true);
  const [professors, setProfessors] = useState([]);

  const handleTopDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCourse(selectedValue);
    setShowDropdown(selectedValue == 'BUAD302');
  }

  const handleProfessorDropdownChange = (event) => {
    setSelectedProfessor(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const linkElem = document.getElementById("goToSurvey");
      console.log(`Submitting ${selectedCourse} ${selectedProfessor}`);
      let response;
      if (selectedCourse === "BUAD304") {
        response = await fetch(`${SERVER_URL}/student/${selectedCourse}`)
        linkElem.innerText = "Go to Survey: BUAD 304"; 
      } else {
        console.log("fetching 302");
        response = await fetch(`${SERVER_URL}/student/${selectedCourse}/${selectedProfessor}`)
        linkElem.innerText = `Go to Survey: ${selectedProfessor}`; 

      }
      const url = await response.json();
      console.log(url);
      linkElem.href = url;
      linkElem.style.visibility = "visible";
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    retrieveProfessors('BUAD302');
  }, []);

  const retrieveProfessors = async (classname) => {
    try {
      const response = await fetch(`${SERVER_URL}/student/${classname}/professors`);
      const data = await response.json();
      setProfessors(data);
      if (data.length > 0) {
        setSelectedProfessor(data[0]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const profItems = professors.map((profName) =>
  <option value={profName} className={profName}>{profName}</option>
  );
  return (
    <div className="Home">
      <img src={ELC_logo} className="elcLogo"/>
      <h1 className="existingSurveys">Select Your Course</h1>
      <select className="surveyList" onChange={handleTopDropdownChange}>
        <option value="BUAD302" className="surveyItem">BUAD 302</option>
        <option value="BUAD304" className="surveyItem">BUAD 304</option>
      </select>
      {showDropdown && (
        <div>
      <h1 className="existingSurveys">Select Your Professor</h1>
      <select className="surveyList" onChange={handleProfessorDropdownChange}>
        {profItems}
      </select>
      </div>
      )}

      <button className="newSurveyButton" onClick={handleSubmit}>Get Survey Link</button>
      <a id="goToSurvey" href="https://example.com" target="_blank">Go To Survey</a>
    </div>
  );
}

export default Student;

import './Home.css';
import React from 'react';
import ELC_logo from './ELC_Logo.jpeg';
import { SERVER_URL } from './globals';
/** Renders page consisting of functionality for editing the current semester */
function EditSemester() {
  // TODO: Update to dynamically accept classname and semester
  const [changeSemVal, setChangeSemVal] = React.useState('');
  const handleChangeSemVal = (event) => {
    setChangeSemVal(event.target.value);
  };
  const changeSemester = async () => {
    try {
      await fetch(`${SERVER_URL}/changeTerm/?term=${changeSemVal}`);
    } catch (error) {
      console.log(error);
    }
  }
  const newSemester = () => {
    window.location.href = "newSemester";
  }
  return (
    <div className="Home">
      <img src={ELC_logo} className="hi"/>
      <h1 className="existingSurveys">Change Current Semester</h1>
      <input type="text" value={changeSemVal} onChange={handleChangeSemVal} className="newSemField" placeholder="Enter New Term {e.g. 20241}"/>
      <button className="changeSurveyButton" onClick={changeSemester}>Change Term</button>
      <button className="newSurveyButton" onClick={newSemester}>Create New Semester</button>
    </div>
  );
}

export default EditSemester;
